import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import * as constants from '../constants';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { TakeActionLink, TakeActionButton } from '../components/buttons';
import Columns from '../components/columns';
import IssueList from '../components/issue-list';
import getTwitterUsername from '../hooks/twitter';
import TextBalancer from '../components/text-balancer';
import ShortDate from '../components/date';
import {
  mapLocation,
  AirtableIssueType,
  AirtableLocation,
  mapIssueType,
} from '../airtable-mapping';
import Sharing from '../components/sharing';

const CityPage: React.FC<{
  pageContext: {
    airtableLocation: AirtableLocation;
    airtableIssueTypes: AirtableIssueType[];
  };
}> = ({ pageContext }) => {
  const city = mapLocation(pageContext.airtableLocation);

  const state = city.containedBy[0];

  const allIssueTypes = pageContext.airtableIssueTypes.map(mapIssueType);

  const official = city.officials[0];

  const actions: React.ReactNode[] = [];

  const pageUrl = typeof window !== 'undefined' ? window.location.href : '';

  useEffect(() => {
    require('sharer.js/sharer.js');
  }, []);

  const showScript = () => {
    const $section = document.querySelector('.beliefs');
    const $header = document.querySelector('.site-header');

    if (!$section) {
      console.error(`Can't find .beliefs section`);
      return;
    }

    const headerHeight = window.innerWidth <= 768 ? $header.offsetHeight : 0;

    window.scrollTo({
      top:
        $section.getBoundingClientRect().top +
        window.pageYOffset -
        headerHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  if (official) {
    if (official.phone) {
      actions.push(
        <div key="phone">
          <h4>
            Call{' '}
            <a className="phone-link" href={'tel:' + official.phone}>
              {official.phone}
            </a>
          </h4>
          <TakeActionLink onClick={showScript}>Script</TakeActionLink>
        </div>
      );
    }

    if (official.email) {
      const emailHref = official.email.includes('@')
        ? `mailto:${official.email}`
        : official.email;

      actions.push(
        <div key="email">
          <h4>Email the {official.roles[0]?.name || 'Legislators'}</h4>
          <TakeActionLink href={emailHref} target="_blank">
            Email
          </TakeActionLink>
        </div>
      );
    }

    if (official.twitter) {
      const twitterUsername = getTwitterUsername(official.twitter);

      actions.push(
        <div key="twitter">
          <h4>Send a Tweet</h4>
          <TakeActionButton
            data-sharer="twitter"
            data-title={
              '@' +
              twitterUsername +
              ' Stop police unions from rigging the system in favor of officers who commit misconduct. Take action now to help #NixThe6!'
            }
            data-url={pageUrl}
          >
            Tweet
          </TakeActionButton>
        </div>
      );
    }

    if (official.website) {
      actions.push(
        <div key="website">
          <h4>View homepage</h4>
          <TakeActionLink href={official.website} target="_blank">
            Visit
          </TakeActionLink>
        </div>
      );
    }
  }

  const cityInfoBlocks: React.ReactNode[] = [];

  if (city.officerRehiringRate) {
    cityInfoBlocks.push(
      <p className="rehiring" key="officerRehiringRate">
        How often the police department rehires bad cops:{' '}
        <a
          href="https://www.washingtonpost.com/graphics/2017/investigations/police-fired-rehired/"
          className="statistic"
        >
          {city.officerRehiringRate <= 1
            ? Math.round(city.officerRehiringRate * 1000) / 10
            : city.officerRehiringRate}
          %
        </a>
      </p>
    );
  }

  if (city.legislatorRole) {
    const legislator = city.officials.find(official => {
      return official.roles.some(role => {
        return role.name === city.legislatorRole?.name;
      });
    });

    const roleName = city.legislatorRole?.name;

    // Because we are abstracting groups of people as a row in the people table,
    // concatenating role before name would produce things like
    // "Board of Supervisors Mendocino Board of Supervisors".
    // Hence we don't concatenate the role name in that case.
    const text = legislator?.name.includes(roleName)
      ? legislator.name
      : `${city.legislatorRole?.name || ''} ${legislator?.name}`;

    cityInfoBlocks.push(
      <p className="legislator" key="legislator">
        Contact {text} to change this contract.
      </p>
    );
  }

  cityInfoBlocks.push(
    <div className="cta-links" key="involvementUrl">
      <a href={city.involvementUrl || constants.volunteerUrl}>
        Get Involved Today
      </a>
      {city.localOrgUrl && <a href={city.localOrgUrl}>Local Organization</a>}
    </div>
  );

  return (
    <>
      <Layout>
        <SEO
          title={`${city.name}${state?.name ? ', ' + state.name : ''}`}
          ogdescription="Stop police unions from rigging the system. Take action now to help #NixThe6!"
        />
        <CityPageContainer>
          <header className="city-header">
            <h2 className="city-name balance-text">
              {city.name}
              {state?.shortName ? ', ' + state.shortName : ''}
            </h2>
          </header>
          {city.contracts.length === 0 && (
            <section className="city-info">
              <div className="details">{cityInfoBlocks}</div>
            </section>
          )}
          {city.contracts.map((contract, i) => {
            const leftBlocks: React.ReactNode[] = [];

            if (contract.expirationDate) {
              leftBlocks.push(
                <div key={contract.id}>
                  <p className="expires">
                    <span className="inner">
                      Expires: <ShortDate theDate={contract.expirationDate} />
                    </span>
                  </p>
                  <p className="small">
                    Even after a contract expires, it usually remains in effect
                    until a new contract is negotiated.
                  </p>
                </div>
              );
            }
            leftBlocks.push(...cityInfoBlocks);

            return (
              <section className="contract-info" key={i}>
                <Columns
                  className={[
                    'details',
                    contract.issues.length > 0 ? 'has-issues' : 'no-issues',
                  ].join(' ')}
                >
                  <div className="left">
                    <h3 className="balance-text">
                      {contract.name
                        ? `${contract.name} Contract`
                        : 'Police Union Contract'}
                    </h3>
                    {leftBlocks}
                  </div>
                  <div className="right contract-issues">
                    <h4>This contract:</h4>
                    <IssueList
                      contract={contract}
                      allIssueTypes={allIssueTypes}
                    />
                    <Sharing
                      copy={{
                        download: 'Download the Contract',
                      }}
                      share={contract.issues.length > 0 ? true : false}
                      urls={{
                        download: contract.document?.url,
                      }}
                    />
                  </div>
                </Columns>
              </section>
            );
          })}
          {actions.length > 0 && (
            <section className="take-action">
              <h2 className="section-title">Take Action</h2>
              <div className="details">
                <Columns className="cta-buttons">{actions}</Columns>
                <Columns className="beliefs">
                  <div className="beliefs-col-1">
                    <h5>We believe:</h5>
                    <ul className="ul-icon li-check">
                      <li>
                        Communities should be able to file anonymous complaints
                        about the actions of police officers and these
                        complaints should not have a statute of limitations on
                        them.
                      </li>
                      <li>
                        Police union contracts create arbitrary and narrow
                        investigatory timelines that lead to discipline being
                        overturned or impossible to implement.
                      </li>
                      <li>
                        Less than 10% of complaints against officers are
                        sustained — this is because of the process being
                        cumbersome or impossible , not because officers are not
                        engaged in misconduct.
                      </li>
                    </ul>
                  </div>
                  <div className="beliefs-col-2">
                    <h5>Elected officials should:</h5>
                    <ul className="ul-icon li-arrow">
                      <li>
                        Ensure that the final decision-maker about all
                        disciplinary decisions is someone directly accountable
                        to the public.
                      </li>
                      <li>
                        Remove any clause that prevents accountability and
                        transparency.
                      </li>
                      <li>Stop accepting any funds from police unions.</li>
                    </ul>
                  </div>
                </Columns>
              </div>
            </section>
          )}
        </CityPageContainer>
      </Layout>
      <TextBalancer />
    </>
  );
};

export const CityPageContainer = styled.div`
  background-color: ${constants.highlightColor};

  p {
    margin-bottom: 1em;
  }

  .details {
    display: flex;
    background: #fff;
    color: ${constants.contentTextColor};
    border-radius: 8px;
    padding: 60px;
    max-width: 1184px;
    margin: 0 auto;

    &.no-columns {
      h3 {
        text-align: center;
      }

      .issue-list {
        margin: auto;
      }
    }

    @media (max-width: 767.98px) {
      padding: 24px;
    }
  }

  .left,
  .right {
    @media (min-width: 992px) {
      flex: 0 0 50%;
    }
  }

  .left {
    @media (min-width: 992px) {
      padding-right: 30px;
    }
  }

  .right {
    margin-top: 3rem;

    @media (min-width: 992px) {
      padding-left: 30px;
      margin-top: 0;
    }
  }

  .city-header {
    padding: 40px 1rem;
  }

  .city-name {
    color: ${constants.highlightForegroundColor};
    text-align: center;
    margin: 0 auto;
    font-size: 72px;
    letter-spacing: 0;
    line-height: 1.2;

    @media (max-width: 767.98px) {
      font-size: 30px;
      line-height: 45px;
    }
  }

  .contract-info,
  .city-info {
    background-color: ${constants.highlightColor};
    margin: 0 auto;
    padding: 0 2rem 90px;

    @media (max-width: 767.98px) {
      padding: 0 1rem 45px;
    }

    h3 {
      text-align: left;
      margin-bottom: 1em;

      @media (max-width: 767.98px) {
        font-size: 21px;
        letter-spacing: 0;
        line-height: 24px;
      }
    }

    .small {
      font-size: 14px;
    }

    .expires {
      color: #fa0000;
      font-size: 22px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0;
      margin-bottom: 0.5rem;

      .inner {
        display: inline-block;
        border: 1px dashed ${constants.contentTextColor};
        background: #f1f1f1;
        padding: 0.5em;
      }

      @media (max-width: 767.98px) {
        font-size: 16px;
        line-height: 28px;
      }
    }

    .rehiring {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 1em;

      a.statistic {
        text-decoration: none;
        font-weight: 900;
        color: ${constants.highlightColor};
        transition: ${constants.colorTransition};

        &:hover {
          color: #dd7f1b;
        }
      }
    }

    .legislator {
      font-size: 18px;
      margin: 2em 0 2.5em 0;
    }

    .cta-links {
      width: 268px;

      a {
        background: ${constants.highlightForegroundColor};
        color: ${constants.highlightColor};
        font-weight: bold;
        border-radius: 8px;
        padding: 1.2em;
        display: block;
        font-size: 16px;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        margin-bottom: 15px;
        transition: ${constants.colorTransition};

        &:hover {
          color: #fff;
        }
      }
    }

    .contract-issues {
      h4 {
        text-transform: uppercase;
        font-size: 21px;
        text-align: center;
        margin-bottom: 2em;
      }
    }
  }

  .take-action {
    padding: 72px 1rem;
    margin: 0 auto;
    background: ${constants.highlightForegroundColor};

    @media (max-width: 767.98px) {
      padding: 41px 1rem;
    }

    h2.section-title {
      font-size: 36px;
      color: #ffffff;
      letter-spacing: 0;
      line-height: 42px;
      text-align: center;
      text-transform: uppercase;
      margin-bottom: 72px;

      @media (max-width: 767.98px) {
        margin-bottom: 41px;
        font-size: 18px;
      }
    }

    h4 {
      font-weight: 900;
      text-align: center;
      margin-bottom: 1em;

      @media (max-width: 767.98px) {
        font-size: 20px;
        color: #181616;
        letter-spacing: 0;
        margin-bottom: 1rem;
        line-height: 36px;
      }
    }

    .details {
      display: flex;
      flex-direction: column;

      .cta-buttons {
        > div:first-of-type {
          padding-right: 0;
        }
        @media (min-width: 768px) {
          justify-content: space-evenly;
        }

        @media (max-width: 767.98px) {
          flex-direction: column;
        }

        .phone-link {
          display: block;
        }

        > div {
          display: flex;
          flex-direction: column;
          text-align: center;
          flex: 1;

          @media (max-width: 767.98px) {
            margin-bottom: 2rem;
          }

          @media (min-width: 768px) {
            margin-right: 3rem;
          }

          &:last-of-type {
            margin-bottom: 0;
            margin-right: 0;
          }

          > a,
          > button {
            margin-top: auto;
            display: block;
          }
        }
      }

      .beliefs {
        position: relative;
        margin-top: 60px;
        justify-content: space-evenly;

        @media (min-width: 768px) {
          &::before {
            content: '';
            display: block;
            width: 3px;
            height: 100%;
            background: #d9d6c3;
            position: absolute;
            top: 0;
            left: calc(50% - 1.5px);
          }
        }

        @media (max-width: 767.98px) {
          margin-top: 46px;
        }

        & > div {
          flex: 0 0 50%;

          @media (max-width: 767.98px) {
            flex: 0 0 100%;
          }
        }
        @media (min-width: 768px) {
          .beliefs-col-1 {
            padding-right: 3rem;
          }

          .beliefs-col-2 {
            padding-left: 3rem;
          }
        }

        h5 {
          text-transform: uppercase;
          margin-bottom: 1em;
          font-size: 21px;
          color: #181616;

          @media (max-width: 767.98px) {
            font-size: 14px;
            letter-spacing: 0.29px;
            line-height: 36px;
          }
        }
      }
    }
  }
`;

export default CityPage;
